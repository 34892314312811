import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
  Circle,
} from "@react-google-maps/api";
import constants from "./config/constants";
import axios from "axios";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

function MapComponent() {
  const mapRef = useRef(null);
  const [tagData, setTagData] = useState([]);
  const [selectedTag, setSelectedTag] = useState("LB5023519");
  const [mapCenter, setMapCenter] = useState({ lat: 33.1749, lng: -86.7705 });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD-XgMXEcxxhOGZ1nNhxtDTGLj9UeVrV4c",
    libraries,
  });
  const [zoom, setZoom] = useState(18);
  const [selectedMapType, setSelectedMapType] = useState("satellite");
  // const [selectedMapType, setSelectedMapType] = useState("roadmap");
  const [hoveredLocation, setHoveredLocation] = useState(null);

  const [lightBugSession, setLightBugSession] = useState("");
  const [lightBugDevices, setLightBugDevices] = useState([]);

  const [showHistory, setShowHistory] = useState(false);
  const [locations, setLocations] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [hoveredCircle, setHoveredCircle] = useState(null);

  const handleMouseOver = (location) => {
    setHoveredCircle(location);
  };

  const handleMouseOut = () => {
    setHoveredCircle(null);
  };

  const handleCircleClick = (location) => {
    setSelectedLocation(location);
  };

  const handleHistoryButtonClick = () => {
    fetchHistory(selectedTag);
    setShowHistory(true); // Show history when the button is clicked
  };

  useEffect(() => {
      if (lightBugDevices.length > 0) {
      fetchLocations(selectedTag); // Initial fetch once devices list is available

      const interval = setInterval(() => {
        fetchLocations(selectedTag); // Fetch every 1 minute
        if (showHistory) {
          fetchHistory(selectedTag); // Fetch history if showHistory is true
        }
      }, 60000); // 60000 milliseconds = 1 minute

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [selectedTag, isLoaded, showHistory, lightBugDevices]);

  useEffect(() => {
    getLoginSession();
  }, []);

  useEffect(() => {
    if (lightBugSession) {
      getDevicesList(lightBugSession);
    }
  }, [lightBugSession]);

  const handleMapTypeChange = (type) => {
    setSelectedMapType(type);
  };

  const getLoginSession = async () => {
    try {
      let response = await fetch(`${constants.baseLightBugURL}/lb_login`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const userID = await response.json();
      setLightBugSession(userID);
    } catch (error) {
      console.error("Error fetching login session:", error);
      alert("Error fetching login session. Please try again later.");
    }
  };

  const getDevicesList = async (userId) => {
    try {
      const deviceResponse = await axios.post(
        `${constants.baseLightBugURL}/lb_devices`,
        { userId: userId }
      );
      const devices = deviceResponse.data;
      console.log("LB devices list");
      console.log(devices);
      setLightBugDevices(devices);

      // Initial fetch after devices are obtained
      fetchLocations(selectedTag);
    } catch (error) {
      console.error("Error fetching devices list:", error);
      alert("Error fetching devices list. Please try again later.");
    }
  };

  const fetchLocations = async (tagId) => {  
    if (!lightBugDevices.length) {
      console.error("LightBug devices are not loaded yet");
      return;
    }

    if (tagId.startsWith("LB")) {
      try {
        const LBdeviceID = tagId.substring(2);
        const device = lightBugDevices.find(
          device => device.id === parseInt(LBdeviceID)
        );
        console.log(`lightBugDevices: ${lightBugDevices}`)
        console.log(`LB device: ${LBdeviceID} ${lightBugDevices} ${device}`)

        const response = await axios.post(
          `${constants.baseLightBugURL}/lb_points`,
          {
            device,
            limit: 1,
          }
        );

        const data = await response.data;
        if (data.length > 0) {
          setTagData(data);
          console.log(data[0], data[0].location.lat, data[0].location.lng);
          setMapCenter({
            lat: parseFloat(data[0].location.lat),
            lng: parseFloat(data[0].location.lng),
          });
        } else {
          setTagData([]);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        alert("Error fetching locations. Please try again later.");
        getLoginSession();
      }
    } else {
      try {
        const response = await fetch(`${constants.baseURL}/get_smart_tag`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tagId: tagId, limit: 1 }),
        });

        const data = await response.json();

        if (data.length > 0) {
          console.log("tagdata", data);
          setTagData(data);
          setMapCenter({
            lat: parseFloat(data[0].lat),
            lng: parseFloat(data[0].lon),
          });
        } else {
          setTagData([]);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        alert("Error fetching locations. Please try again later.");
      }
    }
  };

  const fetchHistory = async (tagId) => {
    if (!lightBugDevices.length) {
      console.error("LightBug devices are not loaded yet");
      return;
    }
    
    if (tagId.startsWith("LB")) {
      try {
        const LBdeviceID = tagId.substring(2);
        const device = lightBugDevices.find(
          device => device.id === parseInt(LBdeviceID)
        );

        const response = await axios.post(
          `${constants.baseLightBugURL}/lb_points`,
          {
            device,
            limit: -1,
          }
        );

        const data = await response.data;
        if (data.length > 0) {
          console.log(555, data);
          setLocations(data);
        } else {
          setLocations([]);
        }
      } catch (error) {
        console.error("Error fetching history:", error);
        alert("Error fetching history. Please try again later.");
        getLoginSession();
      }
    } else {
      try {
        const response = await fetch(`${constants.baseURL}/get_smart_tag`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tagId: tagId,
            limit: -1,
          }),
        });

        const data = await response.json();
        console.log(555, data);
        if (data.length > 0) {
          console.log("tagdata", data);
          setLocations(data);
        } else {
          setLocations([]);
        }
      } catch (error) {
        console.error("Error fetching history:", error);
        alert("Error fetching history. Please try again later.");
      }
    }
  };

  const handleTagChange = (e) => {
    console.log("Locations:", locations);
    console.log("ShowHistory:", showHistory);
    setLocations([]);
    setShowHistory(false);
    setSelectedTag(e.target.value);
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const customMarkerIcon = {
    url: require("./samsung_icon.png"),
    scaledSize: new window.google.maps.Size(55, 35),
  };

  // Function to adjust the latitudinal offset based on zoom level
  function getLatitudinalOffset(zoom) {
    if (zoom >= 18) {
      return 0.0001; // Adjust slightly for higher zoom levels
    } else {
      return 0.0001 * Math.pow(2, 18 - zoom); // No adjustment for lower zoom levels
    }
  }

  function formatTimestamp(timestamp) {
    timestamp = timestamp.replace(/[-:a-zA-Z]/g, "");
    const date = new Date(
      Date.UTC(
        parseInt(timestamp.substring(0, 4)),
        parseInt(timestamp.substring(4, 6)) - 1, // Month is 0-indexed in JavaScript
        parseInt(timestamp.substring(6, 8)),
        parseInt(timestamp.substring(8, 10)),
        parseInt(timestamp.substring(10, 12)),
        parseInt(timestamp.substring(12, 14))
      )
    );
    const output = date.toLocaleString("en-US", {
      timeZone: "America/Chicago",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return output;
  }

  return (
    <div>
      <div className="tag-list">
        <img className="map-logo" src={require("./tabicon.png")} />
        <h2>Select Asset</h2>
        <select id="tagSelect" onChange={handleTagChange} value={selectedTag}>
          {/* <option value="124353673">APC WD DCT6</option>
          <option value="127222300">APC WD DCT3</option>
          <option value="124368127">E04942</option>
          <option value="124368126">E01052</option>
          <option value="124358783">E04825</option>
          <option value="124358784">E00960</option>
          <option value="121968849">E01313</option>
          <option value="121968850">E01312</option> */}
          {lightBugDevices != null && lightBugDevices.length > 0
            ? lightBugDevices
                .filter((device) => device.id === 5023519) // Filter to include only device with id 5023519
                .map((device) => {
                  const deviceId = "LB" + device.id;
                  return (
                    <option key={deviceId} value={deviceId}>
                      {/* {device.id == 4406247 ? "BAT2672GPS" : "BATSHELL1GPS"} */}
                      BATSHELL1GPS
                    </option>
                  );
                })
            : null}
        </select>
        <button className="history-btn" onClick={handleHistoryButtonClick}>
          History
        </button>
      </div>
      <div style={mapContainerStyle}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          center={mapCenter}
          options={{
            mapTypeId: selectedMapType,
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
          }}
          onLoad={(map) => (mapRef.current = map)}
          onMapTypeIdChanged={() => {
            if (mapRef.current) {
              const newMapTypeId = mapRef.current.getMapTypeId();
              setSelectedMapType(newMapTypeId);
            }
          }}
          onZoomChanged={() => {
            if (mapRef.current) {
              const newZoom = mapRef.current.getZoom();
              setZoom(newZoom);
            }
          }}
        >
          {tagData.map((location, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(location.lat || location.location.lat),
                lng: parseFloat(location.lon || location.location.lng),
              }}
              icon={customMarkerIcon} // Use customMarkerIcon
            >
              <InfoWindow
                position={{
                  lat:
                    parseFloat(location.lat || location.location.lat) +
                    getLatitudinalOffset(zoom), // Adjust the latitudinal position slightly
                  lng: parseFloat(location.lon || location.location.lng),
                }}
              >
                <div style={{ padding: "0", margin: "0" }}>
                  {formatTimestamp(
                    location.oprn_crt_date
                      ? location.oprn_crt_date
                      : location.timestamp
                  )}
                </div>
              </InfoWindow>
            </Marker>
          ))}

          {showHistory && locations.length > 0 && (
            <>
              <Polyline
                path={locations.map((location) => ({
                  lat: parseFloat(location.lat || location.location.lat),
                  lng: parseFloat(location.lon || location.location.lng),
                }))}
                options={{
                  strokeColor: "#FF5733", // Custom color for the line
                  strokeOpacity: 0.8, // Adjust the opacity
                  strokeWeight: 4, // Line thickness
                  geodesic: true, // Render the polyline with geodesic curvature
                }}
              />

              {/* {locations.map((location, index) => (
                <>
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(location.lat || location.location.lat),
                      lng: parseFloat(location.lon || location.location.lng),
                    }}
                    // visible={false} // Marker is invisible
                    icon={{
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: 10, // Make the marker essentially invisible
                      fillOpacity: 0,
                      strokeOpacity: 0,
                    }}
                    onMouseOver={() => {
                      setHoveredLocation({
                        lat: parseFloat(location.lat || location.location.lat),
                        lng: parseFloat(location.lon || location.location.lng),
                      });
                    }}
                    onMouseOut={() => setHoveredLocation(null)}
                  />
                  <Circle
                    center={locations}
                    radius={5} // Radius of the circle
                    options={{
                      strokeColor: "#FF5733",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      fillColor: "#FF5733",
                      fillOpacity: 0.5,
                    }}
                  />
                </>
              ))} */}

              {locations.map((location, index) => {
                const center = {
                  lat: parseFloat(location.lat || location.location.lat),
                  lng: parseFloat(location.lon || location.location.lng),
                };
                return (
                  <Circle
                    key={index}
                    center={center}
                    radius={6} // Radius of the circle
                    options={{
                      strokeColor: "#FF5733",
                      strokeOpacity: hoveredCircle === location ? 1 : 0,
                      strokeWeight: 2,
                      fillColor: "#FF5733",
                      fillOpacity: hoveredCircle === location ? 0.5 : 0,
                    }}
                    onMouseOver={() => handleMouseOver(location)}
                    onMouseOut={handleMouseOut}
                    onClick={() => handleCircleClick(location)}
                  />
                );
              })}
              {hoveredCircle && (
                <InfoWindow
                  position={{
                    lat: parseFloat(
                      hoveredCircle.lat || hoveredCircle.location.lat
                    ),
                    lng: parseFloat(
                      hoveredCircle.lon || hoveredCircle.location.lng
                    ),
                  }}
                  onCloseClick={() => setHoveredCircle(null)}
                >
                  <div>
                    {formatTimestamp(
                      hoveredCircle.oprn_crt_date
                        ? hoveredCircle.oprn_crt_date
                        : hoveredCircle.timestamp
                    )}
                  </div>
                </InfoWindow>
              )}

              {/* {hoveredLocation && (
                <Circle
                  center={hoveredLocation}
                  radius={5} // Radius of the circle
                  options={{
                    strokeColor: "#FF5733",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: "#FF5733",
                    fillOpacity: 0.5,
                  }}
                />
              )} */}
            </>
          )}
        </GoogleMap>{" "}
      </div>
    </div>
  );
}

export default MapComponent;
