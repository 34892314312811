import logo from "./logo.svg";
import "./App.css";
import MapComponent from "./Map";
import store from "./store";
import SignIn from "./SignIn";
import { Provider } from "react-redux";
import ThemeProvider from "./theme/ThemeProvider";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import InitLogo from "./InitLogo";
import Error from "./Error";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route exact path="/soco/map" element={<MapComponent />} />
            <Route exact path="/" element={<InitLogo />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
