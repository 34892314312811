import React, { Component } from "react";
class Error extends Component {
  state = {
    data: {},
  };
  render() {
    return <div className="error-page">Error</div>;
  }
}

export default Error;
