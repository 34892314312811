import { Box, Container } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { setUser, loginUser } from "./store/authSlice";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
} from "@mui/material";

import LogoImg from "./tabicon.png";
import { useEffect } from "react";

const OverviewWrapper = styled(Box)(
  () => `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
);

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    if (email == "tomproductions@gmail.com" && password == "123456") {
      dispatch(loginUser({ email, password }));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  return (
    <OverviewWrapper>
      <Container component="main" maxWidth="xs">
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <img className="logo-img-splash logo" src={LogoImg} />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Container>
    </OverviewWrapper>
  );
}

export default SignIn;
